import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import isEmail from 'validator/es/lib/isEmail'
import ReCAPTCHA from 'react-google-recaptcha'
import { set } from 'react-ga'

const INITIAL_DATA = {
  nit: 'CF',
  nombre: '',
  direccion: 'ciudad',
  email: '',
}
function CotizadorDatosPago(props) {
  const [disabled, setDisabled] = useState(true)
  const [datos, setDatos] = useState(INITIAL_DATA)
  const [acepted, setAcepted] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const [isToken, setIsToken] = useState(false)
  const notify = (errorText) =>
    toast.error('Verifique los datos \n' + errorText)
  const notifyEmail = () => toast.error('Verifique su email !')

  useEffect(() => {
    if (datos.nit.toUpperCase() === 'CF') {
      setDisabled(false)
    } else {
      const isData = Object.values(datos).every((el) => Boolean(el))
      if (isData) {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    }
  }, [datos])

  function handleChange(event) {
    const { name, value } = event.target

    setDatos((prevState) => ({ ...prevState, [name]: value }))
  }
  function base64EncodeUnicode(str) {
    var utf8Bytes = encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function (match, p1) {
        return String.fromCharCode('0x' + p1)
      }
    )
    return btoa(utf8Bytes)
  }

  function validateErrors() {
    var isMail = isEmail(datos.email)
    if (!isEmail) {
      notifyEmail()
    }
    return isMail
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const valid = validateErrors()
    if (!valid) {
      notify()
      setLoading(false)
    } else {
      try {
        const url = `https://gacela.c807.com/api.php/express/local/envio`
        const body = [
          {
            tipo_servicio: props.state.datosCotizacion.tipo_envio,
            servicio: props.state.tipo_envio.descripcion,
            detalle: [
              {
                contenido: props.state.datosRemitente.detalle_paquete,
                peso: props.state.datosCotizacion.peso,
                costo: props.state.total,
              },
            ],
            remitente: {
              nombre: props.state.datosRemitente.name_remitente,
              correo: props.state.datosRemitente.email_remitente,
              telefono: props.state.datosRemitente.phone_remitente,
              municipio: props.state.datosRemitente.municipio_origen,
              recolecta: {
                direccion: props.state.datosRemitente.address_remitente,
                fecha: props.state.datosRemitente.pickup_date,
                instrucciones: props.state.datosRemitente.instrucciones_extra,
              },
            },
            destinatario: {
              nombre: props.state.datosDestinatario.name_destinatario,
              correo: props.state.datosDestinatario.email_destinatario,
              telefono: props.state.datosDestinatario.phone_destinatario,
              municipio: props.state.datosDestinatario.municipio_destino,
              entrega: {
                direccion: props.state.datosDestinatario.address_destinatario,
                instrucciones:
                  props.state.datosDestinatario.instructions_destino,
              },
            },
            forma_pago: props.state.datosPagos.tipo_de_pago,

            tarjeta: {
              name: props.state.datosPagos.name,
              number: props.state.datosPagos.number,
              month: props.state.datosPagos.month,
              year: props.state.datosPagos.year,
              cvv: props.state.datosPagos.cvc,
            },

            facturacion: {
              nit: datos.nit,
              nombre: datos.nombre,
              correo: datos.email,
              direccion: datos.direccion,
            },
            fingerprint: props.state.fingerprint,
            recaptcha_token: token,
            articulo: props.state.que_envia,
            cce:{
              monto: props.state.monto_cce?props.state.monto_cce:'',
              banco: props.state.cta_bancaria.banco_id?props.state.cta_bancaria.banco_id:'',
              cuenta_bancaria: props.state.cta_bancaria.no_cuenta?props.state.cta_bancaria.no_cuenta:'',
              modo: props.state.modo_cce?props.state.modo_cce:'',
              tipo_cuenta: props.state.cta_bancaria.tipo_cuenta?props.state.cta_bancaria.tipo_cuenta:''
            }
          },
        ]
        const myJson = JSON.stringify(body)
        const bodyEncoded = base64EncodeUnicode(myJson)

        axios
          .post(url, bodyEncoded, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',

              authorization: 'eeb82452943e85126c0450967b8a15924931db9e',
            },
          })
          .then((res) => {
            const data = res.data

            props.state.guia = data[0].guia
            setLoading(false)
            props.next()
          })
          .catch((error) => {
            let errorMessaje = error.response.data.errores[0].mensaje
            let errorCode = error.response.data.errores[0].codigo

            notify(errorMessaje)
            props.state.tarjetaerror = true
            setLoading(false)
            if (errorCode === 27 || errorCode === 15 || errorCode === 14) {
              props.prev()
            }
          })
      } catch (err) {
        console.error(err)
        setLoading(false)
      }
    }
  }

  const onChangeToken = (value) => {
    setToken(value)

    setIsToken(true)
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <h3 className='color-grensecundary'>Datos de Facturación </h3>
      </div>

      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='nit'>Nit a facturar</label>
          <input
            type='text'
            name='nit'
            className='form-control input-cotizador'
            placeholder='NIT'
            onChange={handleChange}
            value={datos.nit}
          />
        </div>
      </div>
      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='nombre'>Nombre a facturar</label>
          <input
            type='text'
            name='nombre'
            className='form-control input-cotizador'
            placeholder='Nombre'
            onChange={handleChange}
            value={datos.nombre}
          />
        </div>
      </div>

      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='email'>Correo a facturar</label>
          <input
            type='text'
            name='email'
            className='form-control input-cotizador'
            placeholder='Nombre'
            onChange={handleChange}
            value={datos.email}
          />
        </div>
      </div>
      <div className='col-lg-12 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='direccion'>Dirección a facturar</label>
          <input
            type='text'
            name='direccion'
            className='form-control input-cotizador'
            placeholder='Nombre'
            onChange={handleChange}
            value={datos.direccion}
          />
        </div>
      </div>

      <div className='col-12 text-center'>
        <div className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='checkbox'
            name='acepto'
            acepto
            onClick={() => setAcepted(!acepted)}
          />
          <label className='form-check-label' htmlFor='acepto'>
            Acepto condiciones del servicio{' '}
          </label>
        </div>
      </div>

      <div className='Recaptchap-google'>
        <ReCAPTCHA
          sitekey='6LdQtREgAAAAAO99iWrlkmt9Zb7wRNJwYPRXVWKf'
          onChange={onChangeToken}
        />
      </div>

      <div className='col-12 text-center'>
        <div className='form-group'>
          <br />
          {isLoading ? (
            <div
              className='spinner-border'
              role='status'
              width='400px'
              height='400px'
            >
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            <div>
            <button onClick={props.prev} className='btn cotizador-button-back'>
                Anterior
              </button>

              <button
                onClick={handleSubmit}
                className='btn cotizador-button'
                disabled={disabled || acepted || !isToken}
              >
                Aceptar
              </button>
              
            </div>
          )}

          <ToastContainer
            position='bottom-center'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </div>
  )
}

export default CotizadorDatosPago
