import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import isNumeric from 'validator/es/lib/isNumeric'
import isEmail from 'validator/es/lib/isEmail'

const CotizadorRemitente = (props) => {
  const [datos, setDatos] = useState({
    name_remitente: props.state.datosRemitente.name_remitente,
    email_remitente: props.state.datosRemitente.email_remitente,
    phone_remitente: props.state.datosRemitente.phone_remitente,
    address_remitente: props.state.datosRemitente.address_remitente,
    pickup_date: props.state.datosRemitente.pickup_date,
    instrucciones_extra: props.state.datosRemitente.instrucciones_extra,

    detalle_paquete: props.state.detalle_paquete,
  })
  const [departamentos, setDepartamentos] = useState([])
  const [departamentoOrigen, setDepartamentoOrigen] = useState('-1')
  const [municipiosOrigen, setMunicipiosOrigen] = useState([])
  const [disabled, setDisabled] = useState(true)

  const notify = () => toast.error('Verifique los datos')
  const notifyEmail = () => toast.error('Verifique su email !')
  const notifyPhone = () => toast.error('Verifique telefono !')
  const notifyDate = () => toast.error('Verifique la fecha de recolección !')

  useEffect(() => {
    const isData = Object.values(datos).every((el) => Boolean(el))

    isData ? setDisabled(false) : setDisabled(true)
  }, [datos])

  useEffect(() => {
    try {
      const url = `https://gacela.c807.com/api.php/express/local/departamento`
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',

            authorization: 'eeb82452943e85126c0450967b8a15924931db9e',
          },
        })
        .then((res) => {
          const data = res.data
          setDepartamentos(data)
        })
    } catch (err) {
      console.error(err)
    }
  }, [])
  useEffect(() => {
    try {
      if (departamentoOrigen != '-1') {
        const url = `https://gacela.c807.com/api.php/express/local/municipio?departamento=${departamentoOrigen}`
        axios
          .get(url, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',

              authorization: 'eeb82452943e85126c0450967b8a15924931db9e',
            },
          })
          .then((res) => {
            const data = res.data
            setMunicipiosOrigen(data)
          })
      } else {
        setMunicipiosOrigen([])
      }
    } catch (err) {
      console.error(err)
    }
  }, [departamentoOrigen])

  function handleChange(event) {
    const { name, value } = event.target

    setDatos((prevState) => ({ ...prevState, [name]: value }))
  }
  const handleDeptoChangeOrigen = function (e) {
    const opcion = e.target.value
    setMunicipiosOrigen([])
    setDepartamentoOrigen(opcion)
    setDatos((prevState) => ({ ...prevState, ['municipio_origen']: '' }))
  }

  function validateErrors() {
    var isMail = isEmail(datos.email_remitente)
    var isPhone = isNumeric(datos.phone_remitente)
    var dateToday = new Date()
    dateToday.setDate(dateToday.getDate() - 1)
    let dateInsert = new Date(datos.pickup_date)

    let dateVerification = dateInsert >= dateToday
    var phoneComplete = datos.phone_remitente > 9999999
    if (dateVerification) {
      notifyDate()
    }
    if (!isEmail) {
      notifyEmail()
    }
    if (!isPhone || !phoneComplete) {
      notifyPhone()
    }
    console.log(isMail && isPhone && phoneComplete && dateVerification)
    return isMail && isPhone && phoneComplete && dateVerification
  }
  const handleSubmit = function (e) {
    e.preventDefault()

    const valid = validateErrors()
    if (!valid) {
      notify()
    } else {
      props.state.datosRemitente = datos
      props.next()
    }
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <h3 className='color-grensecundary'>Información del Remitente</h3>
      </div>
      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='name_remitente'>Nombre de quien envía </label>
          <input
            type='text'
            name='name_remitente'
            className='form-control input-cotizador'
            placeholder='Nombre Completo'
            onChange={handleChange}
            value={datos.name_remitente}
          />
        </div>
      </div>
      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='email_remitente'>Email </label>
          <input
            type='email'
            name='email_remitente'
            className='form-control input-cotizador'
            placeholder='Email'
            onChange={handleChange}
            value={datos.email_remitente}
          />
        </div>
      </div>
      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='phone_remitente'>Teléfono </label>
          <input
            type='text'
            name='phone_remitente'
            className='form-control input-cotizador'
            placeholder='(502)3453-34534'
            onChange={handleChange}
            value={datos.phone_remitente}
          />
        </div>
      </div>
      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='address_remitente'>Dirección exacta </label>
          <input
            type='text'
            name='address_remitente'
            className='form-control input-cotizador'
            placeholder='Dirección'
            onChange={handleChange}
            value={datos.address_remitente}
          />
        </div>
      </div>
      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='exampleInputEmail1'>Departamento</label>
          <select
            className='form-control input-cotizador'
            name='origen_departamento'
            onChange={handleDeptoChangeOrigen}
          >
            <option value='-1'>Escoja un departamento</option>
            {departamentos.map((items, i) => (
              <option key={i} value={items.id}>
                {items.nombre}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='exampleInputEmail1'>Municipio</label>
          <select
            className='form-control input-cotizador'
            name='municipio_origen'
            onChange={handleChange}
          >
            <option value='-1'>Escoja un Municipio</option>
            {municipiosOrigen.map((items, i) => (
              <option key={i} value={items.id}>
                {items.nombre}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='pickup_date'>Fecha de recolección </label>
          <input
            type='date'
            name='pickup_date'
            className='form-control input-cotizador'
            onChange={handleChange}
            value={datos.pickup_date}
          />
        </div>
      </div>
      <div className='col-lg-8 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='instrucciones_extra'>
            Instrucciones de recolección{' '}
          </label>
          <input
            type='text'
            name='instrucciones_extra'
            className='form-control input-cotizador'
            placeholder='Instrucciones de recolección'
            onChange={handleChange}
            value={datos.instrucciones_extra}
          />
        </div>
      </div>
      <div className='col-lg-12 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='instrucciones_extra'>Detalle del paquete </label>
          <input
            type='text'
            name='detalle_paquete'
            className='form-control input-cotizador'
            placeholder='Descripción del paquete a enviar'
            onChange={handleChange}
            value={datos.detalle_paquete}
          />
        </div>
      </div>

      <div className='col-lg-12 col-md-12'>
        <div className='form-group'>
          <br />
          <button onClick={props.prev} className='btn cotizador-button-back'>
            Anterior
          </button>

          <button
            onClick={handleSubmit}
            className='btn cotizador-button'
            disabled={disabled}
          >
            Siguiente
          </button>

        
          <ToastContainer
            position='bottom-center'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </div>
  )
}

export default CotizadorRemitente
