import React, { Component } from 'react'
import logo from '../../img/logo.svg'
import { Link } from 'react-router-dom'
import { FaWhatsapp, FaFacebookSquare } from 'react-icons/fa'
import { GoMail } from 'react-icons/go'
import { IoLogoInstagram } from 'react-icons/io'
import { IoLocationOutline } from 'react-icons/io5'

class FooterTwo extends Component {
  render() {
    var { fClass } = this.props
    let FooterData = this.props.FooterData
    return (
      <footer className={`footer_area footer_area_four f_bg`}>
        <div className='footer_top'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-3 col-md-6 col-sm-12 d-flex'>
                <img src={logo} className='footer--logo' />
              </div>
              <div className='col-lg-3 col-md-6 col-sm-12'>
                
              </div>
              <div className='col-lg-3 col-md-6 col-sm-12'>
                <p className='footer--links'>
                  {' '}
                  <IoLocationOutline className='footer--icion' /> Calzada
                  Atanasio Tzul, 35-81, zona 12, Guatemala.
                </p>
                <p className='footer--links'>
                  <a href='https://wa.me/50224290909'>
                    {' '}
                    <FaWhatsapp className='footer--icion' /> (502) 2429-0909
                  </a>{' '}
                </p>
                <p className='footer--links'>
                  <a href='#'>
                    {' '}
                    <GoMail className='footer--icion' /> coordinadormk@c807.com
                  </a>{' '}
                </p>
              </div>
              <div className='col-lg-3 col-md-6 col-sm-12'>
                <p className='footer--links'>
                  <a href='https://www.instagram.com/c807xpress/'>
                    {' '}
                    <IoLogoInstagram className='footer--icion' /> /c807xpress
                  </a>
                </p>
                <p className='footer--links'>
                  <a href='https://www.facebook.com/C807Xpress/'>
                    {' '}
                    <FaFacebookSquare className='footer--icion' /> /c807xpress
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='footer_bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 text-center'>
                <span>
                  All right Reserved | 2021 Copyright C807 / Powered by
                  Vaovao.co{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
export default FooterTwo
