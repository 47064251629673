import React, { useState, useEffect } from 'react'
import axios from 'axios'
import isNumeric from 'validator/es/lib/isNumeric'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const INITIAL_DATA = {
  cantidad: '1',
  peso: '10',
  municipio_origen: '',
  municipio_destino: '',
  tipo_envio: '',
  que_envia: '1',
}

function CotizadorSector(props) {
  const [departamentos, setDepartamentos] = useState([])
  const [departamentoOrigen, setDepartamentoOrigen] = useState('-1')
  const [departamentoDestino, setDepartamentoDestino] = useState('-1')
  const [municipiosOrigen, setMunicipiosOrigen] = useState([])
  const [municipiosDestino, setMunicipiosDestino] = useState([])
  const [datos, setDatos] = useState(INITIAL_DATA)
  const [disabled, setDisabled] = useState(true)

  props.state.datosRemitente = {
    name_remitente: '',
    email_remitente: '',
    phone_remitente: '',
    address_remitente: '',
    pickup_date: '',
    instrucciones_extra: '',
    municipio_origen: '',
    detalle_paquete: '',
  }
  props.state.datosDestinatario = {
    municipio_destino: '',
    name_destinatario: '',
    email_destinatario: '',
    phone_destinatario: '',
    address_destinatario: '',
    instructions_destino: '',
  }
  props.state.cta_bancaria = {
    no_cuenta : '',
  }

  const notify = () => toast.error('Verifique Peso y Cantidad!')

  props.state.fingerprint = window.localStorage.getItem('fingerprintID')
  useEffect(() => {
    try {
      const url = `https://gacela.c807.com/api.php/express/local/departamento`
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            authorization: 'eeb82452943e85126c0450967b8a15924931db9e',
          },
        })
        .then((res) => {
          const data = res.data
          setDepartamentos(data)
        })
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    try {
      if (departamentoOrigen != '-1') {
        const url = `https://gacela.c807.com/api.php/express/local/municipio?departamento=${departamentoOrigen}`
        axios
          .get(url, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              authorization: 'eeb82452943e85126c0450967b8a15924931db9e',
            },
          })
          .then((res) => {
            const data = res.data
            setMunicipiosOrigen(data)
          })
      } else {
        setMunicipiosOrigen([])
      }
    } catch (err) {
      console.error(err)
    }
  }, [departamentoOrigen])

  useEffect(() => {
    try {
      if (departamentoDestino != '-1') {
        const url = `https://gacela.c807.com/api.php/express/local/municipio?departamento=${departamentoDestino}`
        axios
          .get(url, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              authorization: 'eeb82452943e85126c0450967b8a15924931db9e',
            },
          })
          .then((res) => {
            const data = res.data
            setMunicipiosDestino(data)
          })
      } else {
        setMunicipiosDestino([])
      }
    } catch (err) {
      console.error(err)
    }
  }, [departamentoDestino])

  useEffect(() => {
    const isData = Object.values(datos).every((el) => Boolean(el))
    isData ? setDisabled(false) : setDisabled(true)
  }, [datos])

  function handleChange(event) {
    const { name, value } = event.target

    setDatos((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleDeptoChangeOrigen = function (e) {
    const opcion = e.target.value
    setMunicipiosOrigen([])
    setDepartamentoOrigen(opcion)
    setDatos((prevState) => ({ ...prevState, ['municipio_origen']: '' }))
  }
  const handleDeptoChangeDestino = function (e) {
    const opcion = e.target.value
    setMunicipiosDestino([])
    setDepartamentoDestino(opcion)
    setDatos((prevState) => ({ ...prevState, ['municipio_destino']: '' }))
  }

  function validateErrors() {
    var PesoIsNumberic = isNumeric(datos.peso, { no_symbols: false })
    var CantidadIsNumberic = isNumeric(datos.cantidad, { no_symbols: false })

    return (
      PesoIsNumberic &&
      CantidadIsNumberic &&
      datos.peso > 0 &&
      datos.cantidad > 0
    )
  }
  const handleSubmit = function (e) {
    e.preventDefault()
    const valid = validateErrors()
    if (!valid) {
      notify()
    } else {
      props.state.datosCotizacion = datos
      props.next()
    }
  }

  //debe haber un use efect para tomar el cambio y otro para validar en tipo los parametros

  return (
    <div className='row'>
      <div className='col-lg-6 col-sm-6'>
        <div className='row'>
          <div className='col-lg-6 col-sm-12'>
            <div className='form-group'>
              <label htmlFor='origen_departamento'>Origen</label>
              <select
                className='form-control input-cotizador'
                name='origen_departamento'
                onChange={handleDeptoChangeOrigen}
              >
                <option value='-1'>Escoja un departamento</option>
                {departamentos.map((items, i) => (
                  <option key={i} value={items.id}>
                    {items.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='col-lg-6 col-sm-12'>
            <div className='form-group'>
              <label htmlFor='origen_municipio'>Municipio</label>
              <select
                className='form-control input-cotizador'
                name='municipio_origen'
                onChange={handleChange}
              >
                <option value='-1'>Escoja un municipio</option>
                {municipiosOrigen.map((items, i) => (
                  <option key={i} value={items.id}>
                    {items.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='col-lg-6 col-sm-12'>
            <div className='form-group'>
              <br />
              <label htmlFor='destino_departamento'>Destino</label>
              <select
                className='form-control input-cotizador'
                name='destino_departamento'
                onChange={handleDeptoChangeDestino}
              >
                <option value='-1'>Escoja un departamento</option>
                {departamentos.map((items, i) => (
                  <option key={i} value={items.id}>
                    {items.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='col-lg-6 col-sm-12'>
            <div className='form-group'>
              <br />
              <label htmlFor='destino_municipio'>Municipio</label>
              <select
                className='form-control input-cotizador'
                name='municipio_destino'
                onChange={handleChange}
              >
                <option value=''>Escoja un municipio</option>
                {municipiosDestino.map((items, i) => (
                  <option key={i} value={items.id}>
                    {items.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='col-lg-6 col-sm-12'>
            <div className='form-group '>
              <br />
              <label htmlFor='que_envia'>¿Qué envías?</label>
              <select
                className='form-control input-cotizador'
                name='que_envia'
                onChange={handleChange}
              >
                <option value='1'>Paquetes</option>
                <option value='2'>Sobres</option>
              </select>
            </div>
          </div>
          <div className='col-lg-3 col-sm-12'>
            <div className='form-group'>
              <br />
              <label htmlFor='cantidad'>Cantidad</label>
              <input
                type='number'
                className='form-control input-cotizador'
                aria-describedby='emailHelp'
                placeholder='1'
                name='cantidad'
                pattern='[0-9]*'
                min='1'
                value={datos.cantidad}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='col-lg-3 col-sm-12'>
            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>Peso Total(Lbs)</label>
              <input
                type='number'
                className='form-control input-cotizador'
                aria-describedby='emailHelp'
                placeholder='1 Lbs'
                name='peso'
                pattern='[0-9]*'
                min='1'
                value={datos.peso}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-6 col-md-6 col-sm-12'>
        <div className='form-group'>
          <label html For='exampleInputEmail1'>¿Dónde pagas?</label> <br />
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input '
              type='radio'
              name='tipo_envio'
              value='SER'
              onClick={handleChange}
            />
            <label className='form-check-label' htmlFor='inlineRadio1'>
              Origen
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              name='tipo_envio'
              value='SEC'
              onClick={handleChange}
            />
            <label className='form-check-label' htmlFor='inlineRadio2'>
              Destino (+Q3.00)
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              name='tipo_envio'
              value='CCE'
              onClick={handleChange}
            />
            <label className='form-check-label' htmlFor='inlineRadio3'>
              COD
            </label>
          </div>
        </div>
        <div className='row'>
          {/* <div className="p-1 ml-1">
                <h1 className=" btn cotizador-button" onClick={(e)=>(setShow(true))} disabled ={disabled}>Cotizar  <i className="fas fa-calculator ml-1"></i></h1>

                </div> */}
          <div className='p-1 ml-1'>
            <button
              onClick={handleSubmit}
              className='btn cotizador-button'
              disabled={disabled}
            >
              <span tooltip='Completar los datos requeridos ⚠️'>Cotizar</span>{' '}
              <i className='fas fa-calculator ml-1'></i>
            </button>
            {/* <button onClick={handleSubmit} className="btn cotizador-button" disabled={props.next}><span tooltip="Completar los datos requeridos ⚠️" >Realizar tu envío </span> <i className="fas fa-box ml-1"></i></button> */}
            <ToastContainer
              position='bottom-center'
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CotizadorSector
