import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import logo from '../../../img/logo.svg'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import isLength from 'validator/lib/isLength'

const INITIAL_DATA = {
  tipo_de_pago: 'efectivo',
  name: '',
  number: '',
  cvc: '',
  expiry: '',
  month: '',
  year: '',
  focused: '',
  date_expiry: '',
}

function CotizadorTipoDePago(props) {
  const [datos, setDatos] = useState(INITIAL_DATA)
  const [disabled, setDisabled] = useState(true)
  const isSEC = props.state.datosCotizacion.tipo_envio === 'SER'

  const notify = (errorText) => toast.error('Verifique los datos de su tarjeta')

  if (props.state.tarjetaerror) {
    setTimeout(function () {
      notify()
    }, 500)
    props.state.tarjetaerror = false
  }
  useEffect(() => {
    if (datos.tipo_de_pago === 'efectivo') {
      setDisabled(false)
    } else if (datos.tipo_de_pago === 'tarjeta') {
      const isData = Object.values(datos).every((el) => Boolean(el))
      const isTarjeta = isLength(datos.number, { min: 16, max: 18 })
      const isCvc = isLength(datos.cvc, { min: 3, max: 4 })
      if (isData) {
        if (isTarjeta && isCvc) setDisabled(false)
      } else {
        setDisabled(true)
      }
    }
  }, [datos])

  function handleChange(event) {
    const { name, value } = event.target

    switch (name) {
      case 'date_expiry':
        var date = value.split('-')

        var mes = date[1]
        var year = date[0].substr(-2)
        var cadena = mes + year

        setDatos((prevState) => ({
          ...prevState,
          expiry: cadena,
          date_expiry: value,
          focused: name,
          month: mes,
          year: year,
        }))

      default:
        setDatos((prevState) => ({
          ...prevState,
          [name]: value,
          focused: name,
        }))
    }
  }

  const handleRevert = function (e) {
    e.preventDefault()
    props.state.datosDestinatario.municipio_destino = ''
    props.prev()
  }

  const handleSubmit = function (e) {
    e.preventDefault()
    props.state.datosPagos = datos
    props.next()
  }

  return (
    <div className='row'>
      <div className='col-lg-12 col-sm-12 text-center'>
        <h1 className='color-grensecundary txt-tipopago'> FORMA DE PAGO </h1>
      </div>

      <div className='col-lg-12 col-md-12'>
        <div className='form-group text-center'>
          <div className='form-group'>
            <label htmlFor='exampleInputEmail1' className='txt-tipopago-p'>
              Selecciona el medio de pago:
            </label>{' '}
            <br />
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input '
                type='radio'
                name='tipo_de_pago'
                value='efectivo'
                onClick={handleChange}
              />
              <label className='form-check-label' htmlFor='inlineRadio1'>
                Efectivo
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='tipo_de_pago'
                value='tarjeta'
                onClick={handleChange}
                disabled={!isSEC}
              />
              <label className='form-check-label' htmlFor='inlineRadio2'>
                Tarjeta
              </label>
            </div>
          </div>
          <h2 className='w_color'>
            Monto a cobrar:{' '}
            <span className='color-grensecundary'>Q.{props.state.total}</span>
          </h2>
        </div>
      </div>

      {datos.tipo_de_pago === 'efectivo' ? (
        <div>
          <img src={logo} />
        </div>
      ) : (
        <div className='col-lg-12'>
          <Cards
            number={datos.number}
            name={datos.name}
            expiry={datos.expiry}
          />
          <br />
          <br />

          <div className='row'>
            <div className='col-lg-4 col-sm-12'>
              <div className='form-group'>
                <label htmlFor='name'>Nombre en la tarjeta:</label>
                <input
                  type='text'
                  name='name'
                  className='form-control input-cotizador'
                  placeholder='Nombre que aparece en la tarjeta'
                  onChange={handleChange}
                  value={datos.name}
                  maxLength='80'
                  minLength='4'
                />
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className='form-group'>
                <label htmlFor='number'>Número de Tarjeta </label>
                <input
                  type='text'
                  name='number'
                  className='form-control input-cotizador'
                  placeholder='Numero de tarjeta'
                  onChange={handleChange}
                  value={datos.numero_tarjeta}
                  pattern='[0-9]*'
                  maxLength='16'
                />
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className='form-group'>
                <label htmlFor='cvc'>CVC </label>
                <input
                  type='password'
                  maxLength='4'
                  name='cvc'
                  className='form-control input-cotizador'
                  placeholder='CVC'
                  pattern='[0-9]*'
                  onChange={handleChange}
                  value={datos.cvc}
                />
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className='form-group'>
                <label htmlFor='email_remitente' value='2023'>Fecha de Vencimiento </label>
                <input
                  type='month'
                  name='date_expiry'
                  className='form-control input-cotizador'
                  onChange={handleChange}
                  value={datos.date_expiry}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='col-lg-12 col-md-12'>
        <div className='form-group text-center'>
          <br />

          <button onClick={props.prev} className='btn cotizador-button-back'>
            Anterior
          </button>

          <button
            className='btn cotizador-button'
            onClick={handleSubmit}
            disabled={disabled}
          >
            Siguiente
          </button>
          
          <ToastContainer
            position='bottom-center'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </div>
  )
}

export default CotizadorTipoDePago
