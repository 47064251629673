import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import isNumeric from 'validator/es/lib/isNumeric'
import isEmail from 'validator/es/lib/isEmail'

const CotizadorDestinatario = (props) => {
  const [disabled, setDisabled] = useState(true)
  const [datos, setDatos] = useState(props.state.datosDestinatario)
  const [departamentos, setDepartamentos] = useState([])
  const [departamentoDestino, setDepartamentoDestino] = useState('-1')
  const [municipiosDestino, setMunicipiosDestino] = useState([])

  const notify = () => toast.error('Vefifique los datos')
  const notifyEmail = () => toast.error('Verifique su email !')
  const notifyPhone = () => toast.error('Verifique telefono !')

  useEffect(() => {
    const isData = Object.values(datos).every((el) => Boolean(el))

    if (isData) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [datos])

  useEffect(() => {
    try {
      const url = `https://gacela.c807.com/api.php/express/local/departamento`
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',

            authorization: 'eeb82452943e85126c0450967b8a15924931db9e',
          },
        })
        .then((res) => {
          const data = res.data
          setDepartamentos(data)
        })
    } catch (err) {
      console.error(err)
    }
  }, [])
  useEffect(() => {
    try {
      if (departamentoDestino !== '-1') {
        const url = `https://gacela.c807.com/api.php/express/local/municipio?departamento=${departamentoDestino}`
        axios
          .get(url, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',

              authorization: 'eeb82452943e85126c0450967b8a15924931db9e',
            },
          })
          .then((res) => {
            const data = res.data
            setMunicipiosDestino(data)
          })
      } else {
        setMunicipiosDestino([])
      }
    } catch (err) {
      console.error(err)
    }
  }, [departamentoDestino])

  function handleChange(event) {
    const { name, value } = event.target

    setDatos((prevState) => ({ ...prevState, [name]: value }))
  }
  const handleDeptoChangeDestino = function (e) {
    const opcion = e.target.value
    setMunicipiosDestino([])
    setDepartamentoDestino(opcion)
    setDatos((prevState) => ({ ...prevState, ['municipio_destino']: '' }))
  }

  function validateErrors() {
    var isMail = isEmail(datos.email_destinatario)
    var isPhone = isNumeric(datos.phone_destinatario)
    var phoneComplete = datos.phone_destinatario > 9999999
    if (!isEmail) {
      notifyEmail()
    }
    if (!isPhone || !phoneComplete) {
      notifyPhone()
    }
    return isMail && isPhone && phoneComplete
  }

  const handleRevert = function (e) {
    e.preventDefault()
    props.state.datosRemitente.municipio_origen = ''
    props.prev()
  }
  const handleSubmit = function (e) {
    e.preventDefault()
    const valid = validateErrors()
    if (!valid) {
      notify()
    } else {
      try {
        const url = `https://gacela.c807.com/api.php/express/local/cotizacion?origen=${props.state.datosCotizacion.municipio_origen}&destino=${props.state.datosCotizacion.municipio_destino}&peso=${props.state.datosCotizacion.peso}&tipo_servicio=${props.state.datosCotizacion.tipo_envio}&cantidad=${props.state.datosCotizacion.cantidad}&cce_monto=${props.state.monto_cce}&cce_modo=${props.state.modo_cce}`
        console.log('estoy imprimiento el url: ',url)
        axios
          .get(url, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',

              authorization: 'eeb82452943e85126c0450967b8a15924931db9e',
            },
          })
          .then((res) => {
            const data = res.data
            data.map((items) => {
              if (props.state.tipo_envio.descripcion === items.descripcion) {
                props.state.total = items.precio
              }
            })

            props.state.datosDestinatario = datos
            props.next()
          })
      } catch (err) {
        console.error('di error', err)
      }
    }
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <h3 className='color-grensecundary'>Información del Destinatario</h3>
      </div>

      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='exampleInputEmail1'>Departamento</label>
          <select
            className='form-control input-cotizador'
            name='destino_departamento'
            onChange={handleDeptoChangeDestino}
          >
            <option value='-1'>Escoja un departamento</option>
            {departamentos.map((items, i) => (
              <option key={i} value={items.id}>
                {items.nombre}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='exampleInputEmail1'>Municipio</label>
          <select
            className='form-control input-cotizador'
            name='municipio_destino'
            onChange={handleChange}
          >
            <option value='-1'>Escoja un Municipio</option>
            {municipiosDestino.map((items, i) => (
              <option key={i} value={items.id}>
                {items.nombre}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='name_destinatario'>Nombre de quien recibe </label>
          <input
            type='text'
            name='name_destinatario'
            className='form-control input-cotizador'
            placeholder='Nombre Completo'
            onChange={handleChange}
            value={datos.name_destinatario}
          />
        </div>
      </div>
      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='email_destinatario'>Email </label>
          <input
            type='email'
            name='email_destinatario'
            className='form-control input-cotizador'
            placeholder='Email'
            onChange={handleChange}
            value={datos.email_destinatario}
            pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$'
          />
        </div>
      </div>
      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='phone_destinatario'>Teléfono </label>
          <input
            type='text'
            name='phone_destinatario'
            className='form-control input-cotizador'
            placeholder='5555-5555'
            onChange={handleChange}
            value={datos.phone_destinatario}
          />
        </div>
      </div>
      <div className='col-lg-4 col-sm-12'>
        <div className='form-group'>
          <label htmlFor='address_destinatario'>Dirección exacta </label>
          <input
            type='text'
            name='address_destinatario'
            className='form-control input-cotizador'
            placeholder='Dirección exacta'
            onChange={handleChange}
            value={datos.address_destinatario}
          />
        </div>
      </div>

      <div className='col-12'>
        <div className='form-group'>
          <label htmlFor='intrucctions_destino'>
            Instrucciones de destinatario{' '}
          </label>
          <input
            type='text'
            name='instructions_destino'
            className='form-control input-cotizador'
            placeholder='Instrucciones de destinatario '
            onChange={handleChange}
            value={datos.instructions_destino}
          />
        </div>
      </div>

      <div className='col-12'>
        <div className='form-group'>
          <br />
          <button onClick={handleRevert} className='btn cotizador-button-back'>
            Anterior
          </button>

          <button
            onClick={handleSubmit}
            className='btn cotizador-button'
            disabled={disabled}
          >
            Siguiente
          </button>
          
          <ToastContainer
            position='bottom-center'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </div>
  )
}

export default CotizadorDestinatario
