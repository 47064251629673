import React from 'react'
import { Steps, Step } from 'react-step-builder'
import TopBarService from '../BarSecundary/Topbar-envio'
import CotizadorSector from './Steps/CotizadorSector'
import CotizadorRemitente from './Steps/CotizadorRemitente'
import CotizadorDestinatario from './Steps/CotizadorDestinatario'
import CotizadorDatosPago from './Steps/CotizadorDatosPago'
import CotizadorTipoServico from './Steps/CotizadorTipoServico'
import CotizadorSucceed from './Steps/CotizadorSucceed'
import CotizadorMonto from './Steps/CotizadorMonto'
import CotizadorTipoDePago from './Steps/CotizadorTipoDePago'

const Cotizador = () => {
  return (
    <div className='container'>
      <h1 className='title-cotizador'>
        <TopBarService />
      </h1>

      <form className='border-test p-5 form-cotizador-bg'>
        <Steps>
          <Step title='Cotizador' component={CotizadorSector} />
          <Step title='MontoCDC' component={CotizadorMonto} />
          <Step title='Tipo Servicio' component={CotizadorTipoServico} />
          <Step title='Datos Remitente' component={CotizadorRemitente} />
          <Step title='Datos Destinatario' component={CotizadorDestinatario} />
          <Step title='Datos de Pago' component={CotizadorTipoDePago} />
          <Step title='Datos de Facturacion' component={CotizadorDatosPago} />
          <Step title='Codigo' component={CotizadorSucceed} />
        </Steps>
      </form>
    </div>
  )
}

export default Cotizador
