import React, { useState, useEffect } from 'react'
import axios from 'axios'
import logo from '../../../img/logo.svg'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import isNumeric from 'validator/es/lib/isNumeric'

const INITIAL_DATA = {
  monto_cod: '',
}

function CotizadorMonto(props) {
  
  
  
  
  const [datos, setDatos] = useState(INITIAL_DATA)
  const [cceModo, setCceModo] = useState('retener')
  const [disabled, setDisabled] = useState(true)
  const notify = () => toast.error('Verifique el monto ingresado!')
  

  if (props.state.datosCotizacion.tipo_envio !== 'CCE') {

    

    try {
      const url = `https://gacela.c807.com/api.php/express/local/cotizacion?origen=${props.state.datosCotizacion.municipio_origen}&destino=${props.state.datosCotizacion.municipio_destino}&peso=${props.state.datosCotizacion.peso}&tipo_servicio=${props.state.datosCotizacion.tipo_envio}&cantidad=${props.state.datosCotizacion.cantidad}`

      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',

            authorization: 'eeb82452943e85126c0450967b8a15924931db9e',
          },
        })
        .then((res) => {
          const data = res.data
          props.state.datos = data
          props.state.monto_cce = ''
          props.state.mondo_cce = ''
          props.next()
        })
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {console.log(cceModo)}, [cceModo])

  useEffect(() => {
    const isData = Object.values(datos).every((el) => Boolean(el))
    isData ? setDisabled(false) : setDisabled(true)
  }, [datos])

  function handleChange(event) {
    const { name, value } = event.target

    setDatos((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  function validateErrors() {
    return isNumeric(datos.monto_cod, { no_symbols: true })
  }
  const handleSubmit = function (e) {
    e.preventDefault()
    const valid = validateErrors()
    if (!valid) {
      notify()
    } else {
      try {
        const url = `https://gacela.c807.com/api.php/express/local/cotizacion?origen=${props.state.datosCotizacion.municipio_origen}&destino=${props.state.datosCotizacion.municipio_destino}&peso=${props.state.datosCotizacion.peso}&tipo_servicio=${props.state.datosCotizacion.tipo_envio}&cantidad=${props.state.datosCotizacion.cantidad}&cce_monto=${datos.monto_cod}&cce_modo=${cceModo}`

        axios
          .get(url, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',

              authorization: 'eeb82452943e85126c0450967b8a15924931db9e',
            },
          })
          .then((res) => {
            const data = res.data
            props.state.datos = data
            props.state.monto_cce = datos.monto_cod
            props.state.modo_cce = cceModo
            props.next()
          })
      } catch (err) {
        console.error(err)
      }

      
    }
  }

  return (
    <div className='container'>
      {props.state.datosCotizacion.tipo_envio !== 'CCE' ? (
        <div className='row'>
          {' '}
          <div className='col text-center'>
            <div
              className='  spinner-border text-center'
              role='status'
              width='1400px'
              height='1400px'
            >
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className='row'>
          <div className='col-lg-12 col-sm-12 text-center form-content-c0d-text'>
          <br />
            <img src={logo} atl='C807Xpress' width='150px' />
            <br />
            <h2 className='color-grensecundary'> Monto a cobrar </h2>
          </div>

          <div className='col-lg-4 col-sm-12 form-content-c0d' >
            <div className='form-group '>
              <br />
              <input
                className='form-control input-cotizador'
                type='number'
                name='monto_cod'
                pattern='[0-9]*'
                value={props.monto_cod}
                onChange={handleChange}
                placeholder='Monto'
              />
            </div>
          </div>

          <div className='col-lg-12 col-sm-6 text-center'>
          <br />
            
            <h6 className='color-grensecundary'> Incluir cobro de envío: </h6>
          </div>
          
          <div className='col-lg-6 col-sm-4 text-center radio-1 '>
          <div className='form-check form-check-inline col-radio'>
            <input
              className='form-check-input '
              type='radio'
              name='cce_modo'
              value='sumar'
              onClick={()=>setCceModo('sumar')}
            />
            <label className='form-check-label' htmlFor='inlineRadio1'>
              Si 
            </label>
          </div>
          </div>
          
          <div className='col-lg-6 col-sm-4 text-center radio-2'>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input '
              type='radio'
              name='cce_modo'
              value='retener'
              onClick={()=>setCceModo('retener')}
            />
            <label className='form-check-label' htmlFor='inlineRadio1'>
              No
            </label>
          </div>
          </div>

         


        
          
           
        



          <div className='col-lg-12 col-md-12'>
            <div className='form-group text-center'>
              <br />
              <button onClick={props.prev} className='btn cotizador-button-back'>
              <span className="arrow-l"><i class="fas fa-chevron-left"></i></span> 
                Anterior
              </button>
              
              <button
                onClick={handleSubmit}
                className='btn cotizador-button'
                disabled={disabled}
              >
                Siguiente
               
               <span className="arrow-r"><i class="fas fa-chevron-right"></i></span> 
              </button>
              

              <ToastContainer
                position='bottom-center'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CotizadorMonto
