import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
/*------ Pages-----*/
import Cotizador from './Pages/Cotizador'
/* import ScrollToTopRoute from "./ScrollToTopRoute"; a*/
import NotFound from './Pages/404'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'



const tagManagerArgs = {
  gtmId: 'GTM-WMLNVMB'
}
TagManager.initialize(tagManagerArgs)


function initizeAnalytics() {
  ReactGA.initialize('UA-150764171-3')
  ReactGA.pageview('/HomePage');
  ReactGA.pageview('/About');
  ReactGA.pageview('/Service');
  ReactGA.pageview('/Cotizado');
  ReactGA.pageview('/ContacPage');
  ReactGA.pageview('/Succed');

}

class App extends Component {
  render() {
    initizeAnalytics()
    return (
      <Router>
        <Switch>
          <Route exact={true} path={'/Cotizador'} component={Cotizador} />
          <Route exact={true} path={'/'} component={Cotizador} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    )
  }
}

export default App
