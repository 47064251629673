import React, { Component } from 'react';
import Cotiz from '../../img/BannerMain/ico/coti.svg';  
import tracking from '../../img/BannerMain/ico/tracking.svg';
import Modal from '../Modal'
import Safe from "react-safe";
import logoTraking from '../../img/svg-beetrack.svg'


class TopBarService extends Component {



  render() {
    return(
      <div className="container">
      <div className="row">
      <div className="col-lg-2 color-envio border-top-service-r">
      <h3>Cotizador</h3>
      </div>
      <div className="col-lg-2 color-envio">
      <h3>Remitente
      y destinatario</h3>
      </div>
      <div className=" col-lg-2 color-envio border-top-service-l">
      <h3>Forma de pago</h3>
      </div>
      </div>
      </div>
    )
  }
    
}

export default TopBarService ;