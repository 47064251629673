import React, { Component } from 'react';
import Check from '../../../img/services-ico/check.svg'
import Economico from '../../../img/services-ico/run.png'






function CotizadorSucceed(props){

    return(
   
    <div className="row">
        <div className="col-lg-12 col-sm-12 text-center">
            <img src ={Check} className="imagen-shield" />
            <h1 className="f_p f_700 f_size_40 w_color wow fadeInLeft" data-wow-delay="0.2s">¡Gracias por tu información! </h1>
            <h3 className="f_p f_700 f_size_40 w_color wow fadeInLeft" data-wow-delay="0.2s">Tu número de guía es <br /><span className="guia_print"><h1><span  className="f_p f_700 f_size_40 green_color wow fadeInLeft" >#</span>{props.state.guia}</h1> </span> </h3>
            <p className="color-grensecundary txt-tipopago-p">Con este número podrás rastrear tu paquete. ¡Guárdalo!</p>
            <p className="color-grensecundary txt-tipopago-p">Recibirás tu guía por correo electrónico.</p>
            <p className="color-grensecundary txt-tipopago-p">¡Recuerda imprimirla y pegarla en tu paquete o sobre!</p>


            <img src = { Economico } className = "Económico" width="400px"/>

        </div>



    </div>
    
    )


}
    


export default CotizadorSucceed ;