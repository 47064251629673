import React from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import Sticky from 'react-stickynode'
import logosvg from '../../src/img/logo.svg'

const CustomNavbar = (props) => {
  const currentRoute = useHistory().location.pathname.toLowerCase()
  var { mClass, nClass, cClass, slogo, hbtnClass } = props
  return (
    <Sticky top={0} innerZ={9999} activeClass='navbar_fixed'>
      <header className='header_area'>
        <nav className={`navbar navbar-expand-lg menu_one ${mClass}`} style={{ paddingTop: '10px', paddingBottom: '10px'}} >
          <div className={`container ${cClass}`}>
            <Link className={`navbar-brand ${slogo}`} to='/'>
              <img src={logosvg} alt='logosvg' />
              <img src={logosvg} alt='logo' />
            </Link>
            <button
              className='navbar-toggler collapsed'
              type='button'
              data-toggle='collapse'
              data-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='menu_toggle'>
                <span className='hamburger'>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span className='hamburger-cross'>
                  <span></span>
                  <span></span>
                </span>
              </span>
            </button>

            <div
              className='collapse navbar-collapse'
              id='navbarSupportedContent'
            >
              
              {/*  <a className={`btn_get btn_hover ${hbtnClass}`} href="#get-app">Get Started</a> */}
            </div>
          </div>
        </nav>
      </header>
    </Sticky>
  )
}

export default CustomNavbar
