import React from 'react';

import CotizadorForm from '../components/Cotizador/CotizadorForm';
import Footer from '../components/Footer/FooterTwo'
import FooterData from '../components/Footer/FooterData';
import CustomNavbar from '../components/CustomNavbar';




const Cotizador = () => (
    <div className="body_wrapper ">
            <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>

        <div className="cotizador cotizador-container">
            <CotizadorForm />
        </div>
        <Footer FooterData={FooterData}/> 
    </div>
)

export default Cotizador;