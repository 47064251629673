import React, { useState, useEffect } from 'react'
import Economico from '../../../img/services-ico/run.png'
import Estandar from '../../../img/services-ico/phone-box-services.png'
import Clock from '../../../img/services-ico/clock.png'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { useHistory } from "react-router-dom";


function RecolectarTexto({valor}){
  return(
    <div className='Mnt_recolectar col-8'>
      Monto a recolectar: <span className='qty_recolectar'> Q {valor}</span>
    </div>
    
  )
}

function ReintegrarTexto({valor}){
  return(
    <div className='Mnt_reintegrar col-8'>
      Monto a reintegrar:  <span className='qty_recolectar'> Q {valor} </span>
    </div>
  )
}

const DATOS_INICIALES = {
  banco_id : '',
  tipo_cuenta : '',
  no_cuenta : ''
  }


const CotizadorTipoServico = (props) => {
  const [tipoEnvio, setTipoEnvio] = useState('')
  const [bancos, setBancos] = useState([])
  const [datosCuenta, setDatosCuenta]= useState({
    banco_id : '',
    tipo_cuenta : '',
    no_cuenta : props.state.cta_bancaria.no_cuenta 
    })
  const notify = () => toast.error('Verifique los datos de la Cta. Bancaria!')

  useEffect(() => {
    try{
      const url_banco = `https://gacela.c807.com/api.php/express/local/banco`
      axios
      .get (url_banco, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
  
          authorization: 'eeb82452943e85126c0450967b8a15924931db9e',
        },
      })
      .then((res) => {
        let dataBanco = res.data
        setBancos(dataBanco)
      })
    }catch (err) {
      console.error(err)
    }

  },[])
  function handleChangeBancoInfo(event){
    const { name, value } = event.target

    setDatosCuenta((prevState) => ({
      ...prevState,
      [name]: value,
    }))

  }

  function validateDataBanco(){
    if(props.state.datosCotizacion.tipo_envio === 'CCE'){
      console.log(datosCuenta)
      const idDataBanco = Object.values(datosCuenta).every((el) => Boolean(el))
      return idDataBanco ? true :  false
    }else {
      return true
    }
  }

  function handleChange(event) {
    event.preventDefault()
    
    props.state.tipo_envio = props.state.datos[0]
    props.state.cta_bancaria = datosCuenta

    let is_valid = validateDataBanco();
    if (is_valid) {
      props.next()
    }else{
      notify()
    }
  }
  function handleChange1(event) {
    event.preventDefault()
    props.state.tipo_envio = props.state.datos[1]
    props.state.cta_bancaria = datosCuenta
    let is_valid = validateDataBanco();
    if (is_valid) {
      props.next()
    }else{
      notify()
    }
  }
  function handleChange2(event) {
    event.preventDefault()
    props.state.tipo_envio = props.state.datos[2]
    props.state.cta_bancaria = datosCuenta
    props.next()
  }

  let navigate = useHistory();
  const handleReturn = () => {
    if(props.state.datosCotizacion.tipo_envio === 'CCE'){
      props.prev();
    }else{
      navigate.replace("/cotizador");
    }
  }

  

  var recolectar;
  var reintegrar;
  
    var recolectar2;
    var reintegrar2;
  if (props.state.datosCotizacion.tipo_envio === 'CCE') {
    recolectar = <RecolectarTexto valor={props.state.datos[0].recolectar} />;
    reintegrar = <ReintegrarTexto valor={props.state.datos[0].reintegrar} />;
    recolectar2 = <RecolectarTexto valor={props.state.datos[1].recolectar} />;
    reintegrar2 = <ReintegrarTexto valor={props.state.datos[1].reintegrar} />;

  } else {
    recolectar =null;
    recolectar2 =null;
    reintegrar = null;
    reintegrar2 =null;
  }
   


  return (
    <div className='row'>
      <div className='col-12'>
        <h3 className='color-grensecundary'>Datos de Envío</h3>
      </div>

      <div className='col-lg-4 col-sm-12 text-center tipos-envio border-white-right  '>
        <h3 className='text-tipo-servicio-t'>
          {props.state.datos[0].descripcion}
        </h3>
        <p className='text-tipo-servicio-p'>
          Servicio de entrega de paquetes con garantía de entrega en 48 horas hábiles.{' '}
        </p>
        <img src={Economico} className='Económico' />
        
        <h1 className='text-tipo-precio'>
          <span className='text-tipo-precio-small'>Q</span>
          {props.state.datos[0].precio}
        </h1>
        {recolectar}
        {reintegrar}

       

        
       
        <br />
        <button onClick={handleChange} className='btn cotizador-button col-7'>
          Elegir{' '}
        </button>
      </div>


      <div className='col-lg-4 col-sm-12 text-center tipos-envio '>
        <h3 className='text-tipo-servicio-t'>
          {props.state.datos[1].descripcion}
        </h3>
        <p className='text-tipo-servicio-p'>
          Servicio de entrega de paquetes con garantía de entrega al día
          siguiente hábil.
        </p>
        
        <img src={Estandar} className='Económico' />
        <h1 className='text-tipo-precio'>
          <span className='text-tipo-precio-small'>Q</span>
          {props.state.datos[1].precio}
        </h1>
       
        {recolectar2}
        {reintegrar2}
        
      
        
        <br />
        <button onClick={handleChange1} className='btn cotizador-button col-7'>
          Elegir
        </button>
      </div>
      {props.state.datosCotizacion.tipo_envio !== 'CCE' ? (
        <div className='col-lg-4 col-sm-12 text-center tipos-envio border-white-left'>
          <h3 className='text-tipo-servicio-t'>
            {props.state.datos[2].descripcion}
          </h3>
          <p className='text-tipo-servicio-p'>
          Servicio de entrega de paquetes con garantía de entrega antes de las 10 de la mañana del día siguiente hábil.
           <strong>*Aplica solo a la capital</strong>
          </p>
          <img src={Clock} className='Económico' />
          <h1 className='text-tipo-precio'>
            <span className='text-tipo-precio-small'>Q</span>
            {props.state.datos[2].precio}
          </h1>
          
          <button onClick={handleChange2} className='btn cotizador-button col-7'>
            Elegir
          </button>
        </div>
      ) : (
        <div className='col-lg-4 col-sm-12 text-center tipos-deposito border-white-left'>
        <h3 className='text-tipo-metodo-deposito'>
        Elige el método de depósito
        </h3>
        
        <label>Banco a depositar:</label>
              <select
                className='form-control input-cotizador'
                name='banco_id'
                onChange={handleChangeBancoInfo}
                
              >
                <option value='-1' >Escoja un Banco</option>

                {bancos.map((items, i) => (
                  <option key={i} value={items.id}>
                    {items.nombre}
                  </option>
                ))}
                
              </select>



              <label>Tipo de cuenta:</label>
              <select
                className='form-control input-cotizador'
                name='origen_departamento'
                name='tipo_cuenta'
                onChange={handleChangeBancoInfo}
                
              >
                <option value='-1' >Tipo de cuenta</option>
                <option value="1">Monetario</option>
                <option value="2">Ahorro</option>
              </select>


              <label>Número de cuenta:</label>
              <input
                type='text'
                className='form-control input-cotizador'
                aria-describedby='emailHelp'
                placeholder='1-111111111-1111-1'
                name='no_cuenta'
                
                value={datosCuenta.no_cuenta}
                onChange={handleChangeBancoInfo}
              />
              </div>

 )}

      <button onClick={handleReturn} className='btn cotizador-button-back-cotizador center-btn '>
        Anterior
      </button>
      <ToastContainer
                position='bottom-center'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />

        </div>
    
  )
}

export default CotizadorTipoServico
